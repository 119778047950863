<template>
	<div id="template">
		<div class="top">
			<div class="title">
				<i></i>
				常用模板列表
			</div>
		</div>
		<el-table :data="tableData" style="width: 100%">
			<el-table-column prop="id" label="编号" width="100" align="cneter"></el-table-column>
			<el-table-column prop="templateName" label="模版名称">
				<template slot-scope="scope">
					<el-link type="primary" :href="scope.row.templatePath" target="_blank">
						{{scope.row.templateName}}</el-link>
				</template>
			</el-table-column>
		</el-table>
	</div>
</template>

<script>
	import {
		getTemplateList
	} from '@/request/api'

	export default {
		name: 'template',
		data() {
			return {
				tableData: []
			}
		},
		created() {
			this.getTemolateListFun()
		},
		mounted() {},
		methods: {
			getTemolateListFun() {
				getTemplateList().then(res => {
					if (res.code == 200) {
						this.tableData = res.data
					}
				})
			}

		}
	}
</script>

<style lang="scss" scoped>
	#template {
		padding: 20px 30px;

		.top {
			// display: flex;
			// justify-content: space-between;
			// align-items: center;
			// background-color: #fff;
			// border-radius: 10px;
			// padding: 15px 20px;
			// box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);

			.title {
				font-size: 20px;
				padding: 0 20px 0 30px;
				position: relative;

				i {
					top: 50%;
					left: 0px;
					width: 20px;
					height: 20px;
					position: absolute;
					border-radius: 50%;
					background-color: #3563E9;
					border: 5px solid #C2D0F8;
					transform: translateY(-50%);
				}
			}
		}
	}
</style>